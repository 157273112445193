import classNames from "classnames";
import { useHistory } from "react-router-dom";

import style from "./style.module.css";

interface Props {
  label: string;
  to: string;
  complete?: boolean;
  className?: string;
}

export function Item({ label, to, complete = false, className }: Props) {
  const history = useHistory();

  const onClick = () => {
    if (!complete) {
      return;
    }
    return history.push(to);
  };

  return (
    <div onClick={onClick} className={classNames(style.item, complete ? style.complete : style.incomplete, className)}>
      <p>{label}</p>
      {complete && <span>Completed</span>}
    </div>
  );

}
