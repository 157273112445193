import * as Sdk from "@screencloud/auth-sdk";

import { config } from "../../../system/config";

interface CreateUserWithPassword {
  email: string;
  password: string;
  givenName?: string | null;
  familyName?: string | null;
}
type LoginWithPassword = Pick<CreateUserWithPassword, "email" | "password">;

export class Id {
  public redirectUrl = `${window.location.protocol}//${window.location.host}/account`;
  public sdk: Sdk.AuthManager;
  
  public constructor() {
    this.sdk = new Sdk.AuthManager({
      service: { url: config.id.service.url },
      frontend: { url: config.id.frontend.url },
      autoRefresh: config.id.refresh,
      autoSync: config.id.sync,
      debug: config.id.debug,
    });
  }

  //
  // Session (shared)
  //

  /**
   * Get the current logged in users session
   */
  public async get(): Promise<Sdk.IAuthSession | null> {
    return this.sdk.get();
  }

  /**
   * Logout from the auth service
   */
  public async logout(): Promise<Sdk.AuthLoggedOutEvent> {
    return this.sdk.logout();
  }

  //
  // Local
  //

  /**
   * Create a new local user
   */
  public async createUserWithPassword(opts: CreateUserWithPassword): Promise<Sdk.CreateUserWithPasswordResponse> {
    const request = await this.sdk.fetchJson("/api/v1/user/create", { body: {
      email: opts.email,
      password: opts.password,
      givenName: opts.givenName,
      familyName: opts.familyName,
      skipVerifyEmail: true,
      migration: true,
    }});
    const exception = request as Sdk.AuthServiceErrorResponse;
    if (exception.error) {
      throw new Error(exception.error.message);
    }
    const response = request as Sdk.CreateUserWithPasswordResponse;
    if (!response.user || !response.user.email) {
      throw new Error("SIGNUP_FAILED");
    }
    return response;
  }

  /**
   * Log a local user in
   */
  public async loginWithPassword(opts: LoginWithPassword): Promise<Sdk.AuthServiceSuccessResponse> {
    const request = await this.sdk.loginWithPassword({
      email: opts.email,
      password: opts.password,
    });
    const exception = request as Sdk.AuthServiceErrorResponse;
    if (exception.error) {
      throw new Error(exception.error.message);
    }
    return request as Sdk.AuthServiceSuccessResponse;
  }

  //
  // Social
  //

  /**
   * Continue with Google (sign up and login)
   */
  public continueWithGoogle(): void {
    window.location.href = `${config.id.service.url}/auth/auth0/google-oauth2?redirectUrl=${this.redirectUrl}`;
  }

  /**
   * Continue with LinkedIn (sign up and login)
   */
  public continueWithLinkedin(): void {
    window.location.href = `${config.id.service.url}/auth/auth0/linkedin?redirectUrl=${this.redirectUrl}`;
  }

  //
  // Utils
  //

  /**
   * Is this email address registered with Auth (not Studio)
   */
  public async userExistsByEmail(email: string): Promise<Sdk.PublicUserIdentity | null> {
    try {
      const request = await this.sdk.userExistsByEmail(email);
      const exception = request as Sdk.AuthServiceErrorResponse;
      if (exception.error ) {
        throw new Error(exception.error.message);
      }
      const user = request as Sdk.UserExistsByEmailResponse;
      if (!user.identities || user.identities.length <= 0) {
        throw new Error("No identities returned from a successful userExistsByEmail request.");
      }
      return user.identities[0];
    } catch (err) {
      return null;
    }
  }

}

export const id = new Id();