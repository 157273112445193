export const config = {
  id: {
    service: {
      url: REACT_APP_ID_SERVICE_URL,
    },
    frontend: {
      url: REACT_APP_ID_FRONTEND_URL,
    },
    refresh: REACT_APP_ID_REFRESH_SESSION === "true",
    sync: REACT_APP_ID_SYNC_SESSION === "true",
    debug: REACT_APP_ID_DEBUG === "true",
  },
  signage: {
    auth: REACT_APP_SIGNAGE_AUTH_URL,
    billing: REACT_APP_SIGNAGE_BILLING_URL,
    backend: REACT_APP_SIGNAGE_SERVICE_URL,
    frontend: REACT_APP_SIGNAGE_FRONTEND_URL,
  },
  studio: {
    eu: {
      frontend: REACT_APP_STUDIO_FRONTEND_EU_URL,
    },
    us: {
      frontend: REACT_APP_STUDIO_FRONTEND_US_URL,
    },
  },
  migrationScreenLimit: 25,
  faqs: {
    hardware: "https://support.screencloud.com/hc/en-gb/articles/360002334218",
    content: "https://support.screen.cloud/hc/en-gb/articles/360006532898-Which-Apps-From-ScreenCloud-Signage-Are-Not-Available-on-ScreenCloud-Studio-",
  },
};
