import store from "store";

import { LocalStorageName } from "../context";

class Storage {

  /**
   * Signage API Token
   */
  public set signageApiToken(token: string) {
    store.set(LocalStorageName.SignageApiToken, token);
  }
  public get signageApiToken() {
    return store.get(LocalStorageName.SignageApiToken) as string || "";
  }

  /**
   * Signage Billing Token
   */
  public set signageBillingToken(token: string) {
    store.set(LocalStorageName.SignageBillingToken, token);
  }
  public get signageBillingToken() {
    return store.get(LocalStorageName.SignageBillingToken) as string || "";
  }
  
  /**
   * Firebase Token
   */
  public set firebaseToken(token: string) {
    store.set(LocalStorageName.FirebaseToken, token);
  }
  public get firebaseToken() {
    return store.get(LocalStorageName.FirebaseToken) as string || "";
  }

  /**
   * CLear localStorage of all tokens
   */
  public clear(): void {
    store.remove(LocalStorageName.SignageApiToken);
    store.remove(LocalStorageName.SignageBillingToken);
    store.remove(LocalStorageName.FirebaseToken);
  }

}

export const storage = new Storage();