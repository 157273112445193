import { config } from "../system/config";

/**
 * Validate password
 * @see studio-auth-service - ripped straight from there
 */
export function isValidPassword(str: string | any): boolean {
  if (typeof str !== "string") {
    return false;
  }
  if (str.length < 8 || str.length > 128) {
    return false;
  }
  return /[a-z]/.test(str) && /[A-Z]/.test(str) && /[0-9]/.test(str);
}

/**
 * Get the URL to send the user to that starts migration (on Studio)
 */
export function getMigrateStudioUrl(orgId: string| undefined, orgName: string| undefined) {
  return `${config.studio.eu.frontend}/migrate/${orgId}?orgName=${encodeURIComponent(orgName || "")}`;
}
// Taken from signcloud-ui. This is how we determine US/EU redirect
// But we are only doing EU right now, so keep this for later.
/* migrateToStudio: function() {
  var { org: { id: orgId }, isStudioRegionLockedToEu } = this.state.data;
  var shouldBeEu = (isStudioRegionLockedToEu || !this.isCountryCodeInUsRegion(this.state.countryCode));
  var studioFrontendUrl = shouldBeEu ? config.studioFrontendEuUrl : config.studioFrontendUsUrl;
  window.location.href = `${studioFrontendUrl}migrate/${orgId}`;
}, */

/**
 * Look for an error in the query string
 */
export function getErrorFromQueryString(): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get("error");
}