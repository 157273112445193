import classNames from "classnames";

import style from "./style.module.css";

export type ReviewType = "account" | "payment" | "hardware" | "content";
interface Props {
  type: ReviewType;
  passed?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export function Review({ type, passed = false, children, className }: Props) {

  const renderTitle = () => {
    switch (type) {
      case "account":
        return "Account";
      case "payment":
        return "Payments";
      case "hardware":
        return "Hardware compatibility";
      case "content":
        return "Content and apps";
      default:
        return "";
    }
  };
  
  const renderInfo = () => {
    switch (type) {
      case "account":
        return passed ? "No issues found on your account." : "There are some issues with your Signage account.";
      case "payment":
        return passed ? "No payment issues found." : "There are some outstanding payments.";
      case "hardware":
        return passed ? "All your screens are compatible with the new ScreenCloud." : "Some of your screens might not be compatible with the new ScreenCloud.";
      case "content":
        return passed ? "All your content and apps will work seamlessly on the new ScreenCloud." : "There are changes to some of your apps and content in the new ScreenCloud.";
      default:
        return "";
    }
  };

  return (
    <div className={classNames(style.container, passed ? style.passed : style.warning, className)}>
      <div className={classNames(style.top, style[type])}>
        <p className={style.title}>{renderTitle()}</p>
        <p className={style.info}>{renderInfo()}</p>
      </div>
      {!passed && children && (
        <div className={style.bottom}>
          {children}
        </div>
      )}
    </div>
  );

}
