import classNames from "classnames";

import { config } from "../../../system/config";
import { Logo } from "../../Logo";
import { Nav } from "../../Nav/Nav";
import { Organization } from "../../Organization";

import style from "./style.module.css";

interface Props {
  className?: string;
}

export function Sidebar(props: Props) {

  return (
    <div className={classNames(style.container, props.className)}>
      <Logo className={style.logo} />
      
      <Organization />

      <div className={style.intro}>
        <p className={style.label}>Account Preparation</p>
        <p className={style.summary}>Before migration can start, we need to check a few things...</p>
      </div>

      <Nav />

      <a href={config.signage.frontend} className={style.signage}>Back to Signage</a>

    </div>
  );

}
