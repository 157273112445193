import { useContext } from "react";

import { AppContext } from "../../../context";
import { Item } from "../Item";

interface Props {
  className?: string;
}

export function Nav(props: Props) {
  const context = useContext(AppContext);

  return (
    <nav className={props.className}>
      <Item label="Checking for issues" to="/" complete={context.canProceed} />
      <Item label="Set up your account" to="/account" />
    </nav>
  );

}
