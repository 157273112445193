import { PublicUserIdentity, IAuthSession } from "@screencloud/auth-sdk";
import { useEffect, useState } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";

import { useLoader } from "./components/Loader/useLoader";
import { AppContext, Context, ContextReviewed } from "./context";
import { Notify } from "./pages/Notify";
import { Step1 } from "./pages/Step1";
import { Step2 } from "./pages/Step2";
import { Google } from "./pages/Step2/Google";
import { Linkedin } from "./pages/Step2/Linkedin";
import { Login } from "./pages/Step2/Login";
import { Signup } from "./pages/Step2/Signup";
import { api } from "./services/signage/Api";
import { billing } from "./services/signage/Billing";
import { id } from "./services/studio/Id";
import { config } from "./system/config";
import { PartnerEmails } from "./system/constants";
import { getMigrateStudioUrl } from "./util";
import { storage } from "./util/storage";

function Routes() {
  const loader = useLoader();
  const history = useHistory();
  const [ org, setOrg ] = useState<Partial<Signage.Api.Org>>({});
  const [ user, setUser ] = useState<Partial<Signage.Api.User>>({});
  const [ screens, setScreens ] = useState<Signage.Api.ScreenCounts | null>(null);
  const [ review, setReview ] = useState<Signage.Api.MigrationReview | null>(null);
  const [ customer, setCustomer ] = useState<Signage.Billing.Customer | null>(null);
  const [ idEmailExistsIdentity, setIdEmailExistsIdentity ] = useState<PublicUserIdentity | null>(null);
  const [ idSession, setIdSession ] = useState<IAuthSession | null>(null);
  const [ canProceed, setCanProceed ] = useState(true);
  const [ account, setAccount ] = useState<ContextReviewed>({ passed: true, notices: [] });
  const [ payment, setPayment ] = useState<ContextReviewed>({ passed: true, notices: [] });
  const [ hardware, setHardware ] = useState<ContextReviewed>({ passed: true, notices: [] });
  const [ content, setContent ] = useState<ContextReviewed>({ passed: true, notices: [] });
  const context: Context = {
    org, setOrg,
    user, setUser ,
    screens, setScreens,
    review, setReview,
    customer, setCustomer,
    idEmailExistsIdentity, setIdEmailExistsIdentity,
    idSession, setIdSession,
    canProceed, setCanProceed ,
    account, setAccount,
    payment, setPayment,
    hardware, setHardware,
    content, setContent,
  };

  useEffect(() => {
    // Build the iframe object
    const iframe = document.createElement("iframe");
    iframe.src = `${config.signage.frontend}/tokens`;
    iframe.width = "0";
    iframe.height = "0";
    iframe.className = "signage-iframe";
    // Listen to it
    window.addEventListener("message", async (event) => {
      if (event.origin !== config.signage.frontend) {
        return;
      }
      if (iframe.contentWindow !== event.source) {
        return;
      }
      const data: { type: "signage_token"; token: string | undefined } = event.data;
      if (typeof data !== "object" || data.type !== "signage_token") {
        return;
      }
      if (!data.token) {
        return logoutAndRedirect();
      }
      storage.signageApiToken = data.token;
      const session = await api.refresh();
      setTokens(session);
      await setData(session);
      console.log(" %c signage-api tokens %c REFRESHED ", "background: #5787C6; color: #fff", "background: #FC8527; color: #fff; font-weight: bold");
      loader.stop();
    });
    // Add it
    document.body.appendChild(iframe);
  }, []);

  /**
   * Set the Signage tokens in storage
   */
  const setTokens = (session: Signage.Api.Session) => {
    storage.signageApiToken = session.signage_token;
    storage.signageBillingToken = session.billing_token;
    storage.firebaseToken = session.firebase_token;
  };

  /**
   * Set the data in the state for the context
   */
  const setData = async (session: Signage.Api.Session) => {
    const org = session.organisation;
    const user = session.user;
    const screens = await api.screenCounts();
    const review = await api.migrationReview();
    const customer = await billing.customer(org.id);
    setOrg(org);
    setUser(user);
    setScreens(screens);
    setReview(review);
    setCustomer(customer);
    setIdEmailExistsIdentity(await id.userExistsByEmail(user.email));
    setIdSession(await id.get());
    watchIdService();
    setReviewAccount(screens, user, org);
    setReviewPayment(customer);
    setReviewHardware(review);
    setReviewContent(review);
  };

  /**
   * Watch the Studio ID service for events
   */
  const watchIdService = () => {
    id.sdk.on("refreshed", ({ claims, token }) => {
      setIdSession({ claims, token });
      console.log(" %c studio-auth-sdk tokens %c REFRESHED ", "background: #5787C6; color: #fff", "background: #FC8527; color: #fff; font-weight: bold");
    });
    id.sdk.on("loggedIn", ({ claims, token }) => {
      setIdSession({ claims, token });
      console.log(" %c studio-auth-sdk tokens %c LOGGEDIN ", "background: #5787C6; color: #fff", "background: #2FB457; color: #fff; font-weight: bold");
    });
    id.sdk.on("loggedOut", () => {
      setIdSession(null);
      console.log(" %c studio-auth-sdk tokens %c LOGGEDOUT ", "background: #5787C6; color: #fff", "background: #D0021B; color: #fff; font-weight: bold");
    });
  };

  /**
   * Set review data for Account
   */
  const setReviewAccount = (screens: Signage.Api.ScreenCounts, user: Signage.Api.User, org: Signage.Api.Org) => {
    if (!screens || !user || !org) {
      return;
    }
    const reviewed: ContextReviewed = { passed: true, notices: [] };
    // Owners only
    if (user.email !== org.owner) {
      reviewed.passed = false;
      setCanProceed(false);
      reviewed.notices.push({
        title: "Only the owner can migration the organization.",
        moreLabel: "Back to Signage",
        moreHref: config.signage.frontend,
      });
    }
    // No paused screens
    if (screens.counts.total > screens.counts.billable) {
      reviewed.passed = false;
      setCanProceed(false);
      reviewed.notices.push({
        title: "Paused screens will be charged after the upgrade. If you no longer use these screens, you can delete them.",
        moreLabel: "Go to screens",
        moreHref: `${config.signage.frontend}/screens`,
      });
    }
    // Active screen limit
    // 2022-06-06: By Seksan: Comment this out for now.
    // Since it prevents many customer from getting migrated.
    /* 
    if (screens.counts.total > config.migrationScreenLimit) {
      reviewed.passed = false;
      setCanProceed(false);
      reviewed.notices.push({
        title: `Currently we can only migrate organizations with ${config.migrationScreenLimit} screens or less.`,
        moreLabel: "Back to Signage",
        moreHref: config.signage.frontend,
      });
    }
    */
    // Partner (reseller or affiliate)
    if (PartnerEmails.includes(user.email)) {
      reviewed.passed = false;
      setCanProceed(false);
      reviewed.notices.push({
        title: "Organizations that are part of our legacy reseller and affiliate program cannot be migrated at this time. Please contact support for help in migration.",
        moreLabel: "Contact us",
        moreHref: `mailto:support@screencloud.com?subject=Legacy affiliate or reseller wanting to migrate to Studio&body=Related email address: ${user.email}`,
      });
    }
    setAccount(reviewed);
  };

  /**
   * Set review data for Payment
   */
  const setReviewPayment = (customer: Signage.Billing.Customer) => {
    if (!customer) {
      return;
    }
    const reviewed: ContextReviewed = { passed: true, notices: [] };
    if (!customer.migration_permission.can_migrate) {
      const moreLabel = "Go to billing";
      const moreHref = `${config.signage.frontend}/account/billing`;
      reviewed.passed = false;
      setCanProceed(false);
      switch (customer.migration_permission.reason) {
        case "HAS_DUE_INVOICES":
          reviewed.notices.push({
            title: "Please resolve outstanding payments before you upgrade.",
            moreLabel: "Make Payment",
            moreHref: customer.migration_permission.pay_url || moreHref,
            moreBlank: true,
          });
          break;
        case "HAS_NO_CHARGEBEE_INFORMATION":
          reviewed.notices.push({
            title: "Your billing account appears to be invalid.",
            moreLabel,
            moreHref,
          });
          break;
        case "NO_SUBSCRIPTION":
          reviewed.notices.push({
            title: "Your billing subscription is missing.",
            moreLabel,
            moreHref,
          });
          break;
        case "INVALID_SUBSCRIPTION_STATUS":
          reviewed.notices.push({
            title: "Your billing account is not active and therefore cannot be migrated.",
            moreLabel,
            moreHref,
          });
          break;
        default:
          reviewed.notices.push({
            title: "Please visit the billing page to fix the issues.",
            moreLabel,
            moreHref,
          });
          break;
      }
      setPayment(reviewed);
    }
  };

  /**
   * Set review data for Hardware
   */
  const setReviewHardware = (review: Signage.Api.MigrationReview | null) => {
    if (!review) {
      return;
    }
    const reviewed: ContextReviewed = { passed: true, notices: [] };
    if (review.screens && review.screens.count) {
      reviewed.passed = false;
      const screens = Object.entries(review.screens).filter(i => i[0] !== "count");
      screens.forEach(i => {
        const screen = i[1];
        if (!screen.count) {
          return;
        }
        reviewed.notices.push({
          title: screen.name,
          note: screen.note,
          moreLabel: "Learn more",
          moreHref: config.faqs.hardware,
          moreBlank: true,
        });
      });
    }
    setHardware(reviewed);
  };

  /**
   * Set review data for Content
   */
  const setReviewContent = (review: Signage.Api.MigrationReview | null) => {
    if (!review) {
      return;
    }
    const reviewed: ContextReviewed = { passed: true, notices: [] };
    if (review.content && review.content.count) {
      reviewed.passed = false;
      const contents = Object.entries(review.content).filter(i => i[0] !== "count");
      contents.forEach(i => {
        const content = i[1];
        if (!content.count) {
          return;
        }
        reviewed.notices.push({
          title: content.name,
          note: content.note,
          description: content.description,
          moreLabel: "Learn more",
          moreHref: config.faqs.content,
          moreBlank: true,
        });
      });
    }
    setContent(reviewed);
  };

  /**
   * Clear the storage and redirect the user back to Signage
   */
  const logoutAndRedirect = () => {
    storage.clear();
    loader.stop();
    setCanProceed(false);
    return history.replace("/notify?error=AUTHORIZE");
  };

  /**
   * Deal with redirects
   * 
   * @description - Big if/elseif condition here to prevent page flash on window.replace()
   */
  useEffect(() => {
    if (loader.isLoading) {
      return;
    }
    if (history.location.pathname.startsWith("/account")) {
      loader.start();
      const path = history.location.pathname.split("/").filter(Boolean).join("/");
      const idSession = context.idSession;
      const identity = context.idEmailExistsIdentity;
      if (idSession) {
        if (idSession.claims.email !== context.user.email) {
          // Logged in already but with wrong email
          loader.stop();
          return history.replace("/notify?error=INCORRECT_EMAIL");
        }
        return window.location.replace(getMigrateStudioUrl(context.org.id, context.org.name));
      } else if (!identity) {
        // Email address don't exist, let them create a new one
        if (path !== "account" && path !== "account/signup") {
          loader.stop();
          return history.replace("/account");
        }
      } else if (identity.strategy === "local") {
        // Already have a local account, let them login
        if (path !== "account/login") {
          loader.stop();
          return history.replace("/account/login");
        }
      } else if (identity.provider === "google-oauth2") {
        // Already have a google account, let them login
        if (path !== "account/google") {
          loader.stop();
          return history.replace("/account/google");
        }
      } else if (identity.provider === "linkedin") {
        // Already have a LinkedIn account, let them login
        if (path !== "account/linkedin") {
          loader.stop();
          return history.replace("/account/linkedin");
        }
      }
      loader.stop();
    }
  });

  if (loader.isLoading) {
    return loader.blocking();
  }

  return (
    <AppContext.Provider value={context}>
      <Switch>
        <Route path="/" exact>
          <Step1 />
        </Route>
        <Route path="/account" exact>
          <Step2 />
        </Route>
        <Route path="/account/signup" exact>
          <Signup />
        </Route>
        <Route path="/account/login" exact>
          <Login />
        </Route>
        <Route path="/account/google" exact>
          <Google />
        </Route>
        <Route path="/account/linkedin" exact>
          <Linkedin />
        </Route>
        <Route path="/notify" exact>
          <Notify />
        </Route>
      </Switch>
    </AppContext.Provider>
  );
}

export const App = withRouter(Routes);