import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function Checkbox({ children, checked, onChange }: Props) {

  return (
    <label className={style.container}>
      <div className={style.left}>
        <input
          type="checkbox"
          className={style.checkbox}
          checked={checked}
          onChange={(event => onChange(event.target.checked))}
        />
      </div>
      <div className={style.right}>
        {children}
      </div>
    </label>
  );

}
