import { useContext } from "react";

import { Button } from "../../components/Buttons/Button";
import { Heading } from "../../components/Heading";
import { Container } from "../../components/Layout/Container";
import { Main } from "../../components/Layout/Main";
import { Section } from "../../components/Layout/Section";
import { Sidebar } from "../../components/Layout/Sidebar";
import { Review, ReviewType } from "../../components/Review/Review";
import { ReviewItem } from "../../components/Review/ReviewItem";
import { AppContext, ContextReviewed } from "../../context";

export function Step1() {
  const context = useContext(AppContext);

  /**
   * Render a review panel
   */
  const renderReview = (type: ReviewType, data: ContextReviewed) => {
    return (
      <Review type={type} passed={data.passed}>
        {data.notices.map(n =>
          <ReviewItem key={Math.random()} title={n.title} note={n.note} description={n.description} moreHref={n.moreHref} moreLabel={n.moreLabel} moreBlank={n.moreBlank} />,
        )}
      </Review>
    );
  };

  return (
    <Container>
      <Sidebar />
      <Main>
        
        <Section wider>
          {context.canProceed ? (
            <Heading title="Your account is ready for migration">
              <p>No issues with your account were found. Click continue to migrate to ScreenCloud Studio.</p>
            </Heading>
          ) : (
            <Heading title="You're almost ready!">
              <p>Before migration can continue, please review the outstanding issues below.</p>
            </Heading>
          )}
        </Section>

        <Section wider>
          {renderReview("account", context.account)}
          {renderReview("payment", context.payment)}
          {renderReview("hardware", context.hardware)}
          {renderReview("content", context.content)}
        </Section>

        {context.canProceed && (
          <Section wider>
            <Button type="link" to="/account">Continue</Button>
          </Section>
        )}
        
      </Main>
    </Container>
  );

}
