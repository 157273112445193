import { useState } from "react";

import style from "./style.module.css";

export function useLoader(initialState = true) {
  const [isLoading, setIsLoading] = useState(initialState);

  const start = () => {
    setIsLoading(true);
  };

  const stop = () => {
    setIsLoading(false);
  };

  const bar = () => {
    if (!isLoading) {
      return null;
    }
    return (
      <div className={style.container}>
        <div className={style.indeterminate} />
      </div>
    );
  };

  const blocking = () => {
    if (!isLoading) {
      return null;
    }
    return (
      <div className={style.blocking}>
        <div className={style.spinner}>
          <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
        </div>
      </div>
    );
  };

  return { isLoading, start, stop, bar, blocking };
}
