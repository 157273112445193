import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../../components/Buttons/Button";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Layout/Container";
import { Main } from "../../../components/Layout/Main";
import { Row } from "../../../components/Layout/Row";
import { Section } from "../../../components/Layout/Section";
import { Sidebar } from "../../../components/Layout/Sidebar";
import { useLoader } from "../../../components/Loader/useLoader";
import { useNotice } from "../../../components/Notice/useNotice";
import { AppContext } from "../../../context";
import { id } from "../../../services/studio/Id";
import { config} from "../../../system/config";
import { getMigrateStudioUrl, getErrorFromQueryString } from "../../../util";

export function Login() {
  const context = useContext(AppContext);
  const history = useHistory();
  const loaderBar = useLoader(false);
  const notice = useNotice();
  const email = context.user.email as string;
  const [ password, setPassword ] = useState("");

  useEffect(() => {
    if (!context.canProceed) {
      return history.replace("/");
    }
    notice.setMessage(getErrorFromQueryString());
  }, []);

  const onSubmit = async () => {
    try {
      loaderBar.start();
      await id.loginWithPassword({ email, password });
      if (context.org) {
        window.location.href = getMigrateStudioUrl(context.org.id, context.org.name);
      }
    } catch (err: any) {
      loaderBar.stop();
      return notice.setMessage(err.message);
    }
  };

  const isDisabled = () => {
    return !password;
  };

  return (
    <Container>
      {loaderBar.bar()}
      <Sidebar />
      <Main>

        <Section>
          <Heading title="Looks like you already have an account">
            <p>You can login and start migrating to the new ScreenCloud.</p>
          </Heading>
        </Section>

        {notice.error()}

        <Section>
          <Form onSubmit={onSubmit}>
            <Row>
              <Input immitate value={email} label="You cannot change your email address" />
            </Row>
            <Row>
              <Input type="password" label="Password" value={password} onChange={setPassword} />
            </Row>
            <Row>
              <Button type="submit" disabled={isDisabled()}>Login and start migration</Button>
            </Row>
            <p className="center"><a href={`${config.id.frontend.url}/forgot`} className="muted" rel="noopener noreferrer" target="_blank">Forgot password?</a></p>
          </Form>
        </Section>

      </Main>
    </Container>
  );

}
