import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../../components/Buttons/Button";
import { Form } from "../../../components/Form";
import { Checkbox } from "../../../components/Form/Checkbox";
import { Input } from "../../../components/Form/Input";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Layout/Container";
import { Main } from "../../../components/Layout/Main";
import { Row } from "../../../components/Layout/Row";
import { Section } from "../../../components/Layout/Section";
import { Sidebar } from "../../../components/Layout/Sidebar";
import { useLoader } from "../../../components/Loader/useLoader";
import { useNotice } from "../../../components/Notice/useNotice";
import { AppContext } from "../../../context";
import { id } from "../../../services/studio/Id";
import { isValidPassword, getMigrateStudioUrl, getErrorFromQueryString } from "../../../util";

import style from "./style.module.css";

export function Signup() {
  const context = useContext(AppContext);
  const history = useHistory();
  const loaderBar = useLoader(false);
  const notice = useNotice();
  const email = context.user.email as string;
  const [ givenName, setGivenName] = useState("");
  const [ familyName, setFamilyName ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ passwordConfirm, setPasswordConfirm ] = useState("");
  const [ terms, setTerms ] = useState(false);

  useEffect(() => {
    if (!context.canProceed) {
      return history.replace("/");
    }
    notice.setMessage(getErrorFromQueryString());
  }, []);

  const onSubmit = async () => {
    try {
      loaderBar.start();
      if (!isValidPassword(password)) {
        throw new Error("INVALID_PASSWORD");
      }
      await id.createUserWithPassword({ email, password, givenName, familyName });
      await id.loginWithPassword({ email, password });
      window.location.href = getMigrateStudioUrl(context.org.id, context.org.name);
    } catch (err: any) {
      loaderBar.stop();
      return notice.setMessage(err.message);
    }
  };

  const isPasswordMismatched = () => {
    return passwordConfirm.length > 0 && passwordConfirm !== password;
  };

  const isDisabled = () => {
    return (!givenName || !familyName || !password || !terms || passwordConfirm !== password);
  };

  return (
    <Container>
      {loaderBar.bar()}
      <Sidebar />
      <Main>

        <Section>
          <Heading title="Finish setting up your account">
            <p>You're almost there, we just need a few more details.</p>
          </Heading>
        </Section>

        {notice.error()}

        <Section>
          <Form onSubmit={onSubmit}>
            <Row columns={2}>
              <Input label="First name" name="firstname" value={givenName} onChange={setGivenName} />
              <Input label="Last name" name="lastname" value={familyName} onChange={setFamilyName} />
            </Row>
            <Row>
              <Input immitate value={email} label="You cannot change your email address" />
            </Row>
            <Row>
              <Input type="password" label="Password" value={password} onChange={setPassword} />
            </Row>
            <Row>
              <Input type="password" label="Confirm password" value={passwordConfirm} onChange={setPasswordConfirm} error={isPasswordMismatched()} />
            </Row>
            <Row className={style.checkbox}>
              <Checkbox checked={terms} onChange={setTerms}>
                I agree to ScreenCloud's{" "}
                <a href="https://screen.cloud/privacy" rel="noopener noreferrer" target="_blank" className="black">Privacy Policy</a> and{" "}
                <a href="https://screen.cloud/terms" rel="noopener noreferrer" target="_blank" className="black">Terms of Conditions</a>.
              </Checkbox>
            </Row>
            <Row>
              <Button type="submit" disabled={isDisabled()}>Finish setup and start migration</Button>
            </Row>
          </Form>
        </Section>

      </Main>
    </Container>
  );

}
