import axios, { Method } from "axios";

import { config } from "../../../system/config";
import { storage } from "../../../util/storage";

export class Api {

  /**
   * Login to Signage
   */
  public async login(authToken: string, provider: Signage.Provider, firebaseLoginToken?: string): Promise<Signage.Api.Session> {
    const request =  await this.request<Signage.Api.Session>({
      url: "/auth/login",
      method: "POST",
      data: {
        auth_token: authToken,
        firebase_login_token: firebaseLoginToken,
        provider,
      },
    });
    if (!request.signage_token) {
      throw new Error("Signage API token not returned.");
    }
    return request;
  }

  /**
   * Refresh Signage tokens
   */
  public async refresh(): Promise<Signage.Api.Session> {
    const request = await this.request<Signage.Api.Session>({
      url: "/auth/token",
      method: "POST",
    });
    if (!request.signage_token) {
      throw new Error("Signage API token not returned.");
    }
    return request;
  }

  /**
   * Return the migration review data
   */
  public async migrationReview(): Promise<Signage.Api.MigrationReview | null> {
    try {
      return await this.request<Signage.Api.MigrationReview>({ url: "/organisations/current/migration-review" });
    } catch (err) {
      return null;
    }
  }

  /**
   * Return the migration review data
   */
  public async screenCounts(): Promise<Signage.Api.ScreenCounts> {
    return this.request<Signage.Api.ScreenCounts>({ url: "/organisations/current/screen_counts" });
  }

  //
  // Private
  //

  /**
   * Make the request to the server
   */
  private async request<T = any>(opts: { url: string; method?: Method; data?: Record<string, unknown> }): Promise<T> {
    const headers: { Authorization?: string } = {
      Authorization: storage.signageApiToken,
    };
    const request = await axios.request<T>({
      baseURL: config.signage.backend,
      url: opts.url,
      method: opts.method || "GET",
      headers,
      data: opts.data,
    });
    if (!request || !request.data) {
      throw new Error("Response from Signage API malformed.");
    }
    return request.data;
  }

}

export const api = new Api();