import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  columns?: number;
  className?: string;
}

export function Row({ children, columns = 1, className }: Props) {

  return (
    <div className={classNames(style.container, style[`columns-${columns}`], className)}>
      {children}
    </div>
  );

}
