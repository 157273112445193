import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Main(props: Props) {

  return (
    <main className={classNames(style.container, props.className)}>
      {props.children}
    </main>
  );

}
