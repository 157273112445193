import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  type: "google" | "linkedin";
  onClick: () => void;
}

export function SocialButton({ children, type, onClick }: Props) {

  return (
    <button type="button" className={classNames(style.container, style[type])} onClick={onClick}>
      {children}
    </button>
  );

}
