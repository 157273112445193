import { PublicUserIdentity, IAuthSession } from "@screencloud/auth-sdk";
import { createContext } from "react";

import { Props as ReviewItemData } from "./components/Review/ReviewItem";

export interface ContextReviewed {
  passed: boolean;
  notices: ReviewItemData[];
}
export enum LocalStorageName {
  SignageApiToken = "signage_api_token",
  SignageBillingToken = "signage_billing_token",
  FirebaseToken = "firebase_token",
}

export interface Context {
  // Signage API
  org: Partial<Signage.Api.Org>;
  setOrg: React.Dispatch<React.SetStateAction<Partial<Signage.Api.Org>>>;
  user: Partial<Signage.Api.User>;
  setUser: React.Dispatch<React.SetStateAction<Partial<Signage.Api.User>>>;
  screens: Signage.Api.ScreenCounts | null;
  setScreens: React.Dispatch<React.SetStateAction<Signage.Api.ScreenCounts | null>>;
  review: Signage.Api.MigrationReview | null;
  setReview: React.Dispatch<React.SetStateAction<Signage.Api.MigrationReview | null>>;
  // Signage Billing
  customer: Signage.Billing.Customer | null;
  setCustomer: React.Dispatch<React.SetStateAction<Signage.Billing.Customer | null>>;
  // Studio ID Service
  idEmailExistsIdentity: PublicUserIdentity | null;
  setIdEmailExistsIdentity: React.Dispatch<React.SetStateAction<PublicUserIdentity | null>>;
  idSession: IAuthSession | null;
  setIdSession: React.Dispatch<React.SetStateAction<IAuthSession | null>>;
  // Review
  canProceed: boolean;
  setCanProceed: React.Dispatch<React.SetStateAction<boolean>>;
  account: ContextReviewed;
  setAccount: React.Dispatch<React.SetStateAction<ContextReviewed>>;
  payment: ContextReviewed;
  setPayment: React.Dispatch<React.SetStateAction<ContextReviewed>>;
  hardware: ContextReviewed;
  setHardware: React.Dispatch<React.SetStateAction<ContextReviewed>>;
  content: ContextReviewed;
  setContent: React.Dispatch<React.SetStateAction<ContextReviewed>>;
}

export const AppContext = createContext<Context>({
  // Signage API
  org: {},
  setOrg: () => {},
  user: {},
  setUser: () => {},
  screens: null,
  setScreens: () => {},
  review: null,
  setReview: () => {},
  // Signage Billing
  customer: null,
  setCustomer: () => {},
  // Studio ID Service
  idEmailExistsIdentity: null,
  setIdEmailExistsIdentity: () => {},
  idSession: null,
  setIdSession: () => {},
  // Review
  canProceed: false,
  setCanProceed: () => {},
  account: { passed: true, notices: [] },
  setAccount: () => {},
  payment: { passed: true, notices: [] },
  setPayment: () => {},
  hardware: { passed: true, notices: [] },
  setHardware: () => {},
  content: { passed: true, notices: [] },
  setContent: () => {},
});