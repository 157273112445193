import classNames from "classnames";

import style from "./style.module.css";

export interface Props {
  title: string;
  note?: string;
  description?: string;
  moreHref?: string;
  moreLabel?: string;
  moreBlank?: boolean;
  className?: string;
}

export function ReviewItem({ title, note, description, moreHref, moreLabel = "Learn more", moreBlank = false, className }: Props) {

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.left}>
        <p className={style.title}>{title}</p>
        {note && <p className={style.note}>{note}</p>}
        {description && <p className={style.description}>{description}</p>}
      </div>
      {moreHref && <div className={style.right}><a href={moreHref} target={moreBlank ? "_blank" : "_self"} rel="noopener noreferrer">{moreLabel}</a></div>}
    </div>
  );

}
