import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Container(props: Props) {

  return (
    <div className={classNames(style.container, props.className)}>
      {props.children}
    </div>
  );

}
