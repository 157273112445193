import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SocialButton } from "../../../components/Buttons/SocialButton";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Layout/Container";
import { Main } from "../../../components/Layout/Main";
import { Section } from "../../../components/Layout/Section";
import { Sidebar } from "../../../components/Layout/Sidebar";
import { useLoader } from "../../../components/Loader/useLoader";
import { useNotice } from "../../../components/Notice/useNotice";
import { AppContext } from "../../../context";
import { id } from "../../../services/studio/Id";
import { getErrorFromQueryString } from "../../../util";

export function Google() {
  const context = useContext(AppContext);
  const history = useHistory();
  const loader = useLoader(false);
  const notice = useNotice();

  useEffect(() => {
    if (!context.canProceed) {
      return history.replace("/");
    }
    notice.setMessage(getErrorFromQueryString());
  }, []);

  const onGoogleClick = () => {
    loader.start();
    return id.continueWithGoogle();
  };

  return (
    <Container>
      {loader.bar()}
      <Sidebar />
      <Main>

        <Section wider>
          <Heading title="Let's get you logged in">
            <p>We've detected that you already have an account for the new ScreenCloud.</p>
          </Heading>
        </Section>

        {notice.error()}

        <Section>
          <SocialButton type="google" onClick={onGoogleClick}>Continue with Google</SocialButton>
        </Section>

      </Main>
    </Container>
  );

}
