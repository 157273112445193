import classNames from "classnames";
import { useContext } from "react";

import { AppContext } from "../../context";

import style from "./style.module.css";

interface Props {
  className?: string;
}

export function Organization({ className }: Props) {
  const context = useContext(AppContext);

  if (!context.org.name) {
    return null;
  }

  return (
    <div className={classNames(style.container, className)}>
      <p className={style.label}>Organization</p>
      <p className={style.name} title={context.org.name}>{context.org.name}</p>
    </div>
  );

}
