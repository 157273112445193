import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Buttons/Button";
import { SocialButton } from "../../components/Buttons/SocialButton";
import { Heading } from "../../components/Heading";
import { Container } from "../../components/Layout/Container";
import { Main } from "../../components/Layout/Main";
import { Section } from "../../components/Layout/Section";
import { Sidebar } from "../../components/Layout/Sidebar";
import { useLoader } from "../../components/Loader/useLoader";
import { useNotice } from "../../components/Notice/useNotice";
import { AppContext } from "../../context";
import { id } from "../../services/studio/Id";
import { getErrorFromQueryString } from "../../util";

export function Step2() {
  const context = useContext(AppContext);
  const history = useHistory();
  const loader = useLoader(false);
  const notice = useNotice();
  const email = context.user.email as string;

  useEffect(() => {
    if (!context.canProceed) {
      return history.replace("/");
    }
    notice.setMessage(getErrorFromQueryString());
  }, []);

  const onGoogleClick = () => {
    loader.start();
    return id.continueWithGoogle();
  };

  const onLinkedinClick = () => {
    loader.start();
    return id.continueWithLinkedin();
  };

  const onLocalClick = () => {
    return history.push("/account/signup");
  };  

  return (
    <Container>
      {loader.bar()}
      <Sidebar />
      <Main>

        <Section>
          <Heading title="Set up your account">
            <p>Let's set up your new credentials for ScreenCloud Studio</p>
          </Heading>
        </Section>

        {notice.error()}

        <Section>
          <SocialButton type="google" onClick={onGoogleClick}>Continue with Google</SocialButton>
          <SocialButton type="linkedin" onClick={onLinkedinClick}>Continue with LinkedIn</SocialButton>
        </Section>

        <Section>
          <p className="muted center">Or use email instead</p>
          <Button type="button" color="white" onClick={onLocalClick}>Continue with {email}</Button>
        </Section>

      </Main>
    </Container>
  );

}
