import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  type?: "text" | "email" | "password";
  value: string;
  label: string;
  onChange?: (value: string) => void;
  autoComplete?: boolean;
  tip?: string;
  className?: string;
  disabled?: boolean;
  immitate?: boolean;
  error?: boolean;
  name?: string;
}

export function Input({ type = "text", name="",label, value, onChange = () => {}, autoComplete = true, tip, className, disabled, immitate = false, error = false }: Props) {

  return (
    <div className={style.container}>
      {immitate ? (
        <div className={style.immitate} title={label}>{value}</div>
      ) : (
        <input
          className={classNames(style.input, className, !error || style.error)}
          type={type}
          value={value}
          placeholder={label}
          onChange={(event => onChange(event.target.value))}
          autoComplete={!autoComplete ? "new-password" : undefined}
          disabled={disabled}
          name={name}
        />
      )}
      
      {tip && <p className="tip shadow-white">{tip}</p>}
    </div>
  );

}
