import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  wider?: boolean;
  less?: boolean;
}

export function Section({ children, wider = false, less = false }: Props) {

  return (
    <div className={classNames(style.container, !wider || style.wider, !less || style.less)}>
      {children}
    </div>
  );

}
