import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Buttons/Button";
import { Heading } from "../../components/Heading";
import { Container } from "../../components/Layout/Container";
import { Main } from "../../components/Layout/Main";
import { Section } from "../../components/Layout/Section";
import { Sidebar } from "../../components/Layout/Sidebar";
import { AppContext } from "../../context";
import { id } from "../../services/studio/Id";
import { config } from "../../system/config";
import { storage } from "../../util/storage";

export function Notify() {
  const context = useContext(AppContext);
  const history = useHistory();
  const [ notice, setNotice ] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    if (error) {
      setNotice(error);
    } 
  }, []);

  const onLogoutClick = async () => {
    await id.logout();
    return history.replace("/account");
  };

  const onLoginClick = () => {
    storage.clear();
    return window.location.replace(`${config.signage.frontend}/logout?redirectUrl=${window.location.protocol}//${window.location.host}`);
  };

  const renderBody = () => {
    switch (notice) {
      case "INCORRECT_EMAIL":
        return (
          <>
            <Section wider>
              <Heading title="You're already logged in to the new ScreenCloud">
                <p>However, to migrate your Signage organization to the new ScreenCloud you must use the same email address as your Signage account.</p>
                <p>Please make sure you're logged on the new ScreenCloud with <strong>{context.user.email}</strong></p>
              </Heading>
            </Section>

            <Section>
              <Button type="button" onClick={onLogoutClick}>Continue</Button>
            </Section>
          </>
        );
      case "AUTHORIZE": 
        return (
          <>
            <Section wider>
              <Heading title="Please login to your account">
                <p>We need to authorize you again to continue with migrating your organization.</p>
                <p>You only have to do this once.</p>
              </Heading>
            </Section>

            <Section>
              <Button type="button" onClick={onLoginClick}>Login</Button>
            </Section>
          </>
        );
      default:
        return (
          <>
            <Section wider>
              <Heading title="Oops! Something went wrong">
                <p>Please try whatever you did again.</p>
              </Heading>
            </Section>

            <Section>
              <Button type="link" to="/">Back</Button>
            </Section>
          </>
        );
    }
  };

  return (
    <Container>
      <Sidebar />
      <Main>
        {renderBody()}
      </Main>
    </Container>
  );

}
