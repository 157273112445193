import { useState } from "react";

import { IdErrorMessages } from "../../system/constants";
import { Section } from "../Layout/Section";

import style from "./style.module.css";

export function useNotice(less = false) {
  const [message, setMessage] = useState<string | null>(null);

  const error = () => {

    if (!message) {
      return null;
    }

    return (
      <Section less={less}>
        <div className={style.error}>
          {IdErrorMessages[message] || message}
        </div>
      </Section>
    );
  };

  return { setMessage, error };
}
