import classNames from "classnames";
import { Link } from "react-router-dom";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  type?: "button" | "submit" | "href" | "link";
  color?: "yellow" | "dark" | "white" | "transparent";
  size?: "default" | "small";
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
  newWindow?: boolean;
  fullWidth?: boolean;
}

export function Button({ children, type = "submit", color = "yellow", size = "default", onClick, to, disabled, newWindow, fullWidth = true }: Props) {

  const renderType = () => {
    switch (type) {
      case "link":
        return (
          <Link to={to || "#"} className={style.button} >
            {children}
          </Link>
        );
      case "href":
        return (
          <a href={to} className={style.button} target={newWindow ? "_blank" : undefined} rel="noreferrer">
            {children}
          </a>
        );
      case "button":
      case "submit":
        return (
          <button type={type} className={style.button} onClick={onClick} disabled={disabled}>
            {children}
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classNames(style.container, style[color], style[size], fullWidth ? style.fullWidth : false)}>
      {renderType()}
    </div>
  );

}
