import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  className?: string;
}

export function Logo(props: Props) {

  return (
    <div className={classNames(style.container, props.className)} title="ScreenCloud">
      ScreenCloud
    </div>
  );

}
