import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  onSubmit: () => void;
}

export function Form({ children, onSubmit }: Props) {

  return (
    <form onSubmit={event => { event.preventDefault(); onSubmit(); }} className={style.container}>
      {children}
    </form>
  );

}
