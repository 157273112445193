import axios, { Method } from "axios";

import { config } from "../../../system/config";
import { storage } from "../../../util/storage";

export class Billing {

  /**
   * Get a customer
   */
  public async customer(orgId: string): Promise<Signage.Billing.Customer> {
    return this.request<Signage.Billing.Customer>({ url: `/customers/${orgId}` });
  }

  //
  // Private
  //

  /**
   * Make the request to the server
   */
  private async request<T = any>(opts: { url: string; method?: Method; data?: Record<string, unknown> }): Promise<T> {
    const headers: { "x-access-token"?: string } = {
      "x-access-token": storage.signageBillingToken,
    };
    const request = await axios.request<T>({
      baseURL: config.signage.billing,
      url: opts.url,
      method: opts.method || "GET",
      headers,
      data: opts.data,
    });
    if (!request || !request.data) {
      throw new Error("Response from Signage Billing API malformed.");
    }
    return request.data;
  }

}

export const billing = new Billing();