import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  title?: string;
  children?: React.ReactNode;
  className?: string;
}

export function Heading({ title, children, className }: Props) {

  return (
    <div className={classNames(style.container, className)}>
      {title && <h1>{title}</h1>}
      {children && <div className={style.intro}>{children}</div>}
    </div>
  );

}
